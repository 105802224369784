import { BaseDto } from './base';

export class Video extends BaseDto<Video> {
	bitRate = '';
  broadDate = '';
  clipsNo = '';
  contents = [
    {
      bitRate: '',
      broadDate: '',
      clipsNo: '',
      episodeId: '',
      playtime: '',
      programId: '',
      thumbnail: '',
      vodId: '',
      vodTitle: '',
      vodType: ''
    }
  ];
  currentElements = 0;
  currentPage = 0;
  episodeId = '';
  lastPage = false;
  pageSize = 0;
  playtime = '';
  programId = '';
  thumbnail = '';
  totalElements = 0;
  totalPages = 0;
  vodId = '';
  vodTitle = '';
  vodType = '';

	constructor(partial?: Partial<Video>) {
		super();

		Object.assign(this, partial);
	}
}

export class ResponseYoutubeVideo extends BaseDto<ResponseYoutubeVideo> {
  etag = '';
  items: YoutubeVideo[] = [];
  kind = '';
  pageInfo: any = {};

  constructor(partial?: Partial<ResponseYoutubeVideo>) {
		super();

		Object.assign(this, partial);
	}
}

export class YoutubeVideo extends BaseDto<YoutubeVideo> {
	etag = '';
  id = '';
  kind = '';
  snippet = {
		channelId: '',
		channelTitle: '',
		description: '',
		playlistId: '',
		position: 0,
		publishedAt: '',
		resourceId: {
			kind: '',
			videoId: '',
		},
		thumbnails: {
			default: {
				url: '',
				width: 0,
				height: 0,
			},
			high: {
				url: '',
				width: 0,
				height: 0,
			},
			maxres: {
				url: '',
				width: 0,
				height: 0,
			},
			medium: {
				url: '',
				width: 0,
				height: 0,
			},
			standard: {
				url: '',
				width: 0,
				height: 0,
			},
		},
    title: '',
    videoOwnerChannelId: '',
    videoOwnerChannelTitle: '',
	};

	constructor(partial?: Partial<YoutubeVideo>) {
		super();

		Object.assign(this, partial);
	}
}