
import * as S from './Footer.styled'
import ImageLogoFooterPc from './assets/LogoFooter__pc.svg'
import ImageLogoFooterMo from './assets/LogoFooter__mo.svg'
import ImageJTBC from '../../assets/images/sponsor/BS_logo_jtbc_wh.svg'
import ImageJTBC2 from '../../assets/images/sponsor/BS_logo_jtbc2_wh.svg'
import ImageJTBC4 from '../../assets/images/sponsor/BS_logo_jtbc4_wh.svg'
import ImageGucci from '../../assets/images/sponsor/BS_logo_gucci_wh.svg'
import ImagePrizm from '../../assets/images/sponsor/BS_logo_prism_wh.svg'
import ImageDonjulio from '../../assets/images/sponsor/BS_logo_donjulio_wh.svg'
import ImageInsta from '../../assets/images/sns/insta.svg'
import ImageYoutube from '../../assets/images/sns/youtube.svg'
import ImageFacebook from '../../assets/images/sns/facebook.svg'
import useMobile from '../../hooks/useMobile'
import IconButtons from "../../stories/Buttons/IconButtons"
import {useState, useRef, useEffect} from 'react'
import { EpisodeInfo } from '../../services/models'
import { useLocation } from 'react-router-dom'

export default function Footer({ episodeInfo }: { episodeInfo: EpisodeInfo }) {
  let location = useLocation()
  let { isMobile } = useMobile()

	const footer = useRef<HTMLElement | null>(null);
	const [visibleTopButton, setVisibleTopButton] = useState(false);
	const [fixedButton, setfixedButton] = useState(true);
  const [history, setHistory] = useState(false)

  const scrollEvent = (e: Event) => {
		const scrollY = window.scrollY;
		const getFooter = footer.current as HTMLElement;

		if (scrollY <= 5) setVisibleTopButton(false);
		else setVisibleTopButton(true);

		if (scrollY + window.innerHeight > getFooter.offsetTop) {
			setfixedButton(false);
		} else {
			setfixedButton(true);
		}
	};

	const slideTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	useEffect(() => {
    setHistory(location.pathname === '/history')
		window.addEventListener('scroll', scrollEvent);

		return () => {
			window.removeEventListener('scroll', scrollEvent);
		};
	}, [location, location.pathname, location.hash]);

  return (
    <S.Footer ref={footer}>
      <img src={isMobile ? ImageLogoFooterMo : ImageLogoFooterPc} alt={`제 ${episodeInfo.episodeNo}회 백상예술대상 Baeksang Arts Award`} />
      <ul className="sponsor">
        <li>
          <span className="label">방송</span>
          <div className="content">
            <span className='hidden'>JTBC</span>
            <img src={ImageJTBC} alt="jtbc 로고" />
            <span className='hidden'>JTBC2</span>
            <img src={ImageJTBC2} alt="jtbc2 로고" />
            <span className='hidden'>JTBC4</span>
            <img src={ImageJTBC4} alt="jtbc4 로고" />
          </div>
        </li>
        <li>
          <span className="label">디지털 중계</span>
          <div className="content">
            <span className='hidden'>prizm</span>
            <img src={ImagePrizm} alt="prizm 로고" />
          </div>
        </li>
        <li>
          <span className="label">협찬</span>
          <div className="content">
            <span className='hidden'>gucci</span>
            <img src={ImageGucci} alt="gucci 로고" />
            <span className='hidden'>prizm</span>
            <img src={ImagePrizm} alt="prizm 로고" />
            <span className='hidden'>donjulio</span>
            <img src={ImageDonjulio} alt="donjulio 로고" className='donjulio'/>
          </div>
        </li>
      </ul>

      <ul className="socials">
        <li>
          <a href={process.env.REACT_APP_instagram} target="_blank"><img src={ImageInsta} alt="인스타그램 로고 이미지" /></a>
        </li>
        <li>
          <a href={process.env.REACT_APP_youtube} target="_blank"><img src={ImageYoutube} alt="유튜브 로고 이미지" /></a>
        </li>
        <li>
          <a href={process.env.REACT_APP_facebook} target="_blank">
            <img src={ImageFacebook} alt="페이스북 로고 이미지" />
          </a>
        </li>
      </ul>
      <p className="copyright">Copyrightⓒ HLL All Rights Reserved</p>
      
			{visibleTopButton && (
        <IconButtons
          className={`top-button ${fixedButton ? 'fixed' : ''} ${history?'history':''}`}
          size={!isMobile?50:36}
          onClick={()=>{slideTop()}}
          rounded={true}
          reverse={history}
        >
          TOP
        </IconButtons>
      )}
    </S.Footer>
  )
}